.footer {
   background-image: linear-gradient(180deg, #1C1C1C 0%, #2C2C2C 100%);
}

@media only screen and (max-width: 600px) {
   .footer {
      background-image: radial-gradient(101.02% 101.02% at 1.2% 0%, #3E3E3E 1.04%, #000000 100%);
   }
 }

#submitBtn {
   background-image: linear-gradient(135deg, #69E4A3 0%, #D2E66A 56.25%, #FDE753 100%);
}